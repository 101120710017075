@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900');

body {
  font-family: Source Sans Pro, sans-serif;
}

@mixin accFont($size: 'normal', $weight: 'regular', $multiline: false) {
  font-size: var(--font-size-#{$size});
  font-weight: var(--font-weight-#{$weight});

  @if $multiline {
    @if $size == 's' or $size == 'normal' {
      line-height: var(--font-line-height-#{$size});
    }
  }
}

$fontSizesMap: (
  xxs: var(--font-size-xxs),
  xs: var(--font-size-xs),
  s: var(--font-size-s),
  normal: var(--font-size-normal),
  l: var(--font-size-l),
  xl: var(--font-size-xl),
  xxl: var(--font-size-xxl),
  h: var(--font-size-h),
  xh: var(--font-size-xh),
);

$fontWeightsMap: (
  light: var(--font-weight-light),
  regular: var(--font-weight-regular),
  semibold: var(--font-weight-semibold),
  bold: var(--font-weight-bold),
  extrabold: var(--font-weight-extrabold),
);

$fontLineHeightsMap: (
  s: var(--font-line-height-s),
  normal: var(--font-line-height-normal),
);

@each $size, $fontSize in $fontSizesMap {
  @each $weight, $fontWeight in $fontWeightsMap {
    .font-#{$size}-#{$weight} {
      font-size: $fontSize;
      font-weight: $fontWeight;
      line-height: normal;
    }

    @if $size == 's' or $size == 'normal' {
      .font-multiline-#{$size}-#{$weight} {
        font-size: $fontSize;
        font-weight: $fontWeight;
        line-height: map-get($fontLineHeightsMap, $size);
      }
    }
  }
}
