/* You can add global styles to this file, and also use other style files */

// Project styles
@use 'app';
@use 'new-font';
@use 'new-ghosts';
@use 'new-theme';

// All scss files below are added in angular.json, they live in libs/styles
// {projectName}.architect.build.options.stylePreprocessorOptions.includePaths

// Accredible styles
@use 'new-accessibility';
@use 'mixins';
@use 'reset';
@use 'responsive';

// Accredible New Icon Font
@use 'acc-new-icon-font/style';

// Rich Text Editor styles
@use 'rich-text';

@import '@angular/cdk/overlay-prebuilt.css';
