@use 'responsive';

.cf-content-horizontal {
  width: 100%;
  max-width: calc(1380px + var(--spacing-12x));
  margin: 0 auto;
  padding: 0 var(--spacing-3x);

  @include responsive.media-gt(sm) {
    padding: 0 var(--spacing-6x);
  }
}

body {
  a {
    color: var(--acc-link-color);
  }
}
