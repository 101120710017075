body {
  // Focus indicator
  *:focus {
    outline: var(--acc-outline);
    outline-offset: 0;
  }
}

// Used to hide an element from the user, but make it so screen readers still get to it and read its value
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
